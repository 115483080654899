import React, { useState, useEffect } from "react";
import { graphql, Link } from "gatsby";
import styled from 'styled-components';
import { StaticImage, GatsbyImage, getImage } from 'gatsby-plugin-image';

import Layout from "../layouts";
import Seo from "../components/seo";
import BreakpointUp from "../components/Media/BreakpointUp"
import {
	BreadCrumb,
	Section,
	BaseButton,
	SectionPageTitle,
	SectionDescription,
} from '../components/Section'
import CtaSection from '../sections/CtaSection';

const HeroContainer = styled.div`
	border-top: 1px solid #D0D0D0;
	border-bottom: 1px solid #D0D0D0;
	padding: 40px 0;
	margin-bottom: 41px;
`

const MainPanel = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 -10px;
  ${BreakpointUp.lg`
        margin:0 -20px;
    `}
`
const ProductList = styled.div`
	position: relative;
  width: 100%;
  padding: 0 10px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  order: 1;
  ${BreakpointUp.lg`
    padding:0 20px;
    flex: 0 0 75%;
    max-width: 75%;
    order:0;
  `}
`
const GalleryItem = styled.div`
	position: relative;
	width: calc(50% - 20px);
	margin-bottom: 20px;

	.gatsby-image-wrapper {
		width: 100%;
		height: 100%;
	}
	@media(max-width: 767px) {
		width: 100%;
		margin-bottom: 30px;
	}

	h3 {
		position: absolute;
		bottom: 0;
		right: 0;
		padding: 10px 20px;
		margin-bottom: 0;
		font-size: 16px;
		color: #ffffff;
		line-height: 1.5;
		background: rgba(0, 0, 0, .5);
`


const SkuWrap = styled.div`
	position: absolute;
	z-index: 2;
	top: 10px;
	left: -22px;
	width: 180px;

	.gatsby-image-wrapper {
		width: 100%;
		height: 100%;
	}
	.sku {
		position: absolute;
		top: 8px;
		left: 30px;
		width: 150px;
		font-size: 14px;
		line-height: 19px;
		color: white;
		text-transform: uppercase;
	}
`

const LoadMoreButton = styled(BaseButton)`
  background: #D51333;
  color:#fff;
  border-radius: 6px;
  box-shadow: 0 6px 0 #982221;
  font-family: "Oswald", sans-serif;
  margin: auto;
  display: block;
  width: fit-content;

  &:after {		
    background: #A20000;
  }
  &:hover {
    cursor: pointer;
  }
`

const Gallery = ({ data, location }) => {
	const [showLimit, setShowLimit] = useState(10);
	const products = data.allContentfulProduct.edges;
	const [currentCategory, setCurrentCategory] = useState('all');
	const [currentSubCategory, setCurrentSubCategory] = useState("all");
	const [currentProducts, setCurrentProducts] = useState(products);

	useEffect(() => {
		let currentProductsTemp = [];
		products.forEach(function(item) {
			if (item.node.category?.name === currentCategory) {
				currentProductsTemp.push(item);
			}
		})
		setCurrentSubCategory('all');
		setCurrentProducts(currentProductsTemp)
			
	}, [currentCategory, setCurrentCategory]);

	useEffect(() => {
		if (currentCategory === 'all') {
			setCurrentProducts(products);
		} else {
			if (currentSubCategory === 'all') {
				let currentProductsTemp = [];
				products.forEach(function(item) {
					if (item.node.category?.name === currentCategory) {
						currentProductsTemp.push(item);
					}
				})
				setCurrentProducts(currentProductsTemp)
			} else {
				let currentProductsTemp = [];
				products.forEach(function(item) {
					if (item.node.category?.name === currentCategory && item.node.subCategory?.name ===currentSubCategory) {
						currentProductsTemp.push(item);
					}
				})
				setCurrentProducts(currentProductsTemp)
			}
		}
	}, [currentSubCategory, setCurrentSubCategory]);

	return(
		<Layout footerCta location={location}>
			<Seo title="Check out our Product Gallary - Buildings And More" description="Check out the pictures of steel buildings, storage buildings, pole barns, gazebos, greenhouses, and screen rooms that we offer." />
			<BreadCrumb position="relative">
				<div className="container">
					<Link to="/">Home</Link><span>&nbsp;/&nbsp;</span>
					<span>Product Gallery</span>
				</div>
			</BreadCrumb>
			<Section pb="0" pt="0" mb="100px" mpt="0" mpb="0">
				<HeroContainer className="container">
					<SectionPageTitle>Product Gallery</SectionPageTitle>
					<SectionDescription maxWidth="1120px">
						We stock a range of styles and sizes, including steel buildings, storage buildings, pole barns, gazebos, greenhouses, and screen rooms. Take a look at our products to find the right one that fits your needs.
					</SectionDescription>
				</HeroContainer>
				<div className="container">
					<MainPanel>
						{/*<LeftSidbar>
							<div className="isSticky">
								{
									categories.map((category, i) => {
										return(
											<CategoryWrap>
												<CategoryItem
													onClick={()=> handleCategory(category.node.name)}
													key={i}
													active={category.node.name===currentCategory?true:false}
												>
													{category.node.name} <ArrowRightIcon fill="#E43D3C" />
												</CategoryItem>
												{category.node.name===currentCategory && subCategories?.map((item, i) =>{
													if (item.node.category.name === currentCategory) {
														return(
															<SubCategoryItem
																onClick={()=>setCurrentSubCategory(item.node.name)}
																active={item.node.name===currentSubCategory?true:false}
															>
																{item.node.name}
															</SubCategoryItem>
														)
													}
												})}
											</CategoryWrap>
										)
									})
								}
							</div>
						</LeftSidbar>*/}
						<ProductList>
						{
							currentProducts.map((item, i) => i < showLimit && (
								<GalleryItem>
									<SkuWrap>
										<StaticImage
											src="../images/sku.png"
											placeholder="blurred"
											formats={["AUTO", "WEBP", "AVIF"]}
											alt="sku"
										/>
										<div className="sku">
											sku: {item.node.sku}
										</div>
									</SkuWrap>
									<GatsbyImage image={getImage(item.node.image.gatsbyImageData)} alt={item.node.name} />
									<h3>{item.node.name}</h3>
								</GalleryItem>
							))
						}
						{currentProducts.length > showLimit && (
							<LoadMoreButton onClick={()=>setShowLimit(showLimit + 10)}>Load More Buildings</LoadMoreButton>
						)}
						</ProductList>
					</MainPanel>
				</div>
			</Section>
			<CtaSection />
		</Layout>
	)
}

export default Gallery

export const query = graphql`
  query GalleryPageQuery {
  	allContentfulProduct {
      edges {
        node {
          category {
            name
          }
          subCategory {
          	name
          }
          name
          sku
          width
          length
          height
          image {
            gatsbyImageData(placeholder: BLURRED, quality: 50)
          }
          startingPrice
          downPayment
          url
        }
      }
    }
    allContentfulCategory {
	    edges {
	      node {
	        name
	      }
	    }
	  }
	  allContentfulSubCategory {
	    edges {
	      node {
	        category {
	          name
	        }
	        name
	      }
	    }
	  }
  }
`